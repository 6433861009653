import ajaxNewsletter from './modules/ajax-newsletter';
import cardSimpleImage from './modules/card/card-simple-image';
import careerFilter from './modules/career-filter';
import characterPopup from './modules/characterPopup';
import contactInfoFilters from './modules/contactInfoFilters';
import files from './modules/files';
import footer from './modules/footer';
import header from './modules/header';
import headerTopbar from './modules/header-topbar';
import headerSearch from './modules/headerSearch';
import inputHandler from './modules/inputHandler';
import languageSwitcher from './modules/languageSwitcher';
import postFeatured from './modules/postFeatured';
import pressList from './modules/pressList';
import search from './modules/search';
import sectionToggler from './modules/sectionToggler';
import select2Handler from './modules/select2Handler';
import wcagPanel from './modules/wcagPanel';
import initMegamenuVideo from './modules/megamenuVideo';
import FormDatepicker from './modules/form-datepicker';

var documentReady = function () {
    const selects2 = $('[data-select]');

    require('./modules/chart-analysts');

    // console.log('DOM Loaded');
    footer();
    languageSwitcher();
    header();
    headerSearch();
    pressList();
    postFeatured();
    inputHandler();
    select2Handler(selects2);
    search();
    wcagPanel();
    contactInfoFilters();
    sectionToggler();
    headerTopbar();
    files();
    careerFilter();
    cardSimpleImage();
    ajaxNewsletter();
    initMegamenuVideo();
    // Zmienna globalna przenosząca tłumaczenia. Konfigurowalna w config.php.
    // console.log(i18n);

    const charCards = document.querySelectorAll('.card-character');
    charCards.forEach(item => characterPopup(item));

    const formDataPickers = document.querySelectorAll('[data-form-picker]');

    if (formDataPickers.length > 0) {
        formDataPickers.forEach(picker => {
            const pickerClass = new FormDatepicker(picker);
        });
    }
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
