import debounce from 'lodash.debounce';

export default function headerSearch() {
    let isSearchOpened = false;

    $('.js-trigger-search').on('click', () => {
        isSearchOpened = !isSearchOpened;

        if (window.innerWidth > 1379) {
            if (isSearchOpened) {
                $('.header').addClass('active-desktop');
                $('.header-menu').addClass('-hide');
                $('.header-brand__logo').addClass('d-none');
                $('.header-brand__wrapper').addClass('show');
                $('.header-menu__megamenu').removeClass('active');
                $('.header-menu__item--depth-0 ').removeClass('active');
                isSearchOpened = !isSearchOpened;
            } else {
                $('.header-menu').removeClass('-hide');
                $('.header').removeClass('active-desktop');
                $('.header-brand__logo').removeClass('d-none');
                $('.header-brand__wrapper').removeClass('show');
            }
        }
        $('.js-trigger-search').addClass('d-none');
        $('.header-search__form-wrapper').toggleClass('active');
        $('.header__right-wrapper').toggleClass('-search-opened');
    });

    $('.js-close-search').on('click', () => {
        $('.header-search__form-wrapper').removeClass('active');
        $('.header__right-wrapper').removeClass('-search-opened');
        $('.js-trigger-search').removeClass('d-none');
        $('.header-menu').removeClass('-hide');

        if (
            !$('.header').hasClass('is-scrolling') &&
            !$('.header-menu__megamenu').hasClass('active')
        ) {
            $('.header').removeClass('active-desktop');
            $('.header-brand__logo').removeClass('d-none');
            $('.header-brand__wrapper').removeClass('show');
            $('.header-menu').removeClass('active');
            $('.header-search').removeClass('active');
        }
    });

    $('.js_header_search_input').keyup(
        debounce(function (e) {
            const lang = e.target.getAttribute('data-lang');
            handleSearchAjax($(e.target).val(), lang);
        }, 500)
    );

    $(document).on(
        'click',
        '.header-search__mobile-input-icon.icon-close',
        () => {
            clearSearch();
        }
    );

    const handleSearchAjax = (query, lang) => {
        const searchResults = $('.js_header_search_results');
        const searchIcon = $('.header-search__mobile-input-icon');

        if (!query) {
            searchResults.empty();
            searchResults.removeClass('is-visible');
            searchIcon.removeClass('icon-close');
            searchIcon.addClass('icon-search');
            return;
        }

        searchIcon.removeClass('icon-search');
        searchIcon.addClass('icon-close');

        $.ajax({
            url: ajax.url,
            method: 'POST',
            data: {
                action: 'search',
                query: query,
                lang
            },
        })
            .then(function (response, status, xhr) {
                searchResults.empty();
                searchResults.addClass('is-visible');

                if (status == 'success' && response.success == true) {
                    searchResults.html(response.data.content);
                }
            })
            .catch(function (error) {
                console.log(error);
                searchResults.empty();
                searchResults.html(response.data.message);
            });
    };

    const clearSearch = () => {
        $('.header-search__mobile-input-icon').removeClass('icon-close');
        $('.header-search__mobile-input-icon').addClass('icon-search');
        $('.js_header_search_results').empty();
        $('.js_header_search_results').removeClass('is-visible');
        $('.js_header_search_input').val('');
    };
}
